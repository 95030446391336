import axios from 'axios';

//export const config = localStorage.getItem("token");

/*export const api = axios.create({
  baseURL: 'https://app.bluedoctor.com.br',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const dominio = 'https://app.bluedoctor.com.br';*/

/*export const api = axios.create({
  baseURL: 'https://tcdoc.viaconect.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const dominio = 'https://tcdoc.viaconect.com';*/
export const api = axios.create({
  baseURL: 'https://tcdoc.viacorptec.com.br',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const dominio = 'https://tcdoc.viacorptec.com.br';
/*export const api = axios.create({
  baseURL: 'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const dominio = 'http://localhost:8080';*/
